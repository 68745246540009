import React from "react";
import "./QuoteSection.css";
import StaticQuotes from "../images/static_quotes.png";

export default function QuoteSection({ children, ...rest }) {
  const { backgroundColor, textColor, marginBottom } = rest;
  return (
    <div
      className="staticQuotesContainer"
      style={{ backgroundColor, marginBottom }}
    >
      <div className="quoteSection">
        <p
          className="quoteText"
          style={{
            color: textColor,
          }}
        >
          {children}
        </p>
        <h2 className="quote-author">Gracy and Family</h2>
        <img src={StaticQuotes} className="quoteImg" alt="" />
      </div>
    </div>
  );
}
