import "./FormInput.css";

export default function FormInput({ children, borderRight, ...rest }) {
  const { id } = rest;
  return (
    <div className="inputField">
      <label style={{ display: "none" }} htmlFor={id}>
        {children}
      </label>
      <input {...rest} style={{ borderRight }} />
    </div>
  );
}
