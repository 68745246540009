import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardElement,
  Elements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import "./DonationForm.css";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import rightArrow from "./images/rightArrow.png";
import loading from "./images/loading.gif";
import FormInput from "./components/FormInput";
import PaymentRequestForm from "./components/PaymentRequestForm";
import Form from "./components/Form";
const API_BASE_URL = "https://dmf-donation-back-end.herokuapp.com"; // Replace with your actual API base URL

const apiKey = process.env.REACT_APP_STRIPE_API_KEY;

const stripePromise = loadStripe(apiKey);

const DonationForm = () => {
  const ONE_TIME = "one_time";
  const MONTHLY = "monthly";
  const selectedButton = "button-selected";
  const [selectedMonths, setSelectedMonths] = useState(12); // Default to 3 months for monthly donation

  const stripe = useStripe();
  const elements = useElements();
  const [donationType, setDonationType] = useState(ONE_TIME);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState(""); // Change this line
  const [lastName, setLastName] = useState("");
  const [memo, setMemo] = useState("");
  const [amount, setAmount] = useState("");
  const [prevAmount, setPrevAmount] = useState("");

  const [totalAmount, setTotalAmount] = useState("");
  const [finalAmount, setFinalAmount] = useState("");
  const [clickedItem, setClickedItem] = useState(1);
  const [dfnInfo, setDfnInfo] = useState("");
  const [dfnCVCPIN, setDfnCVCPIN] = useState("");
  const navigate = useNavigate();
  const [cardNumber, setCardNumber] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [cardNumberPledger, setCardNumberPledger] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [expirationDatePledger, setExpirationDatePledger] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [addedFee, setAddedFee] = useState("");
  const [paymentFinalAmount, setPaymentFinalAmount] = useState(0); // Initialize with a default
  const [feeClicked, setFeeClicked] = useState("false");

  // const [expirationError, setExpirationError] = useState("");

  const [coverFees, setCoverFees] = useState(false);

  useEffect(() => {
    if (amount !== prevAmount) {
      // console.log("Input has changed:", prevAmount, "->", amount);
      setPrevAmount(amount);
    }
  }, [amount, prevAmount]);

  useEffect(() => {
    if (clickedItem === 2 && amount !== prevAmount) {
      // If on Google Pay and amount has changed
      setTimeout(() => {
        setClickedItem(8); // Switch to ZELLE
        setTimeout(() => {
          setClickedItem(2); // Switch back to GOOGLE PAY
        }, 11);
      }, 11);
    }
  }, [clickedItem, amount]);

  useEffect(() => {
    const calculateTotalAmount = () => {
      if (!amount) return;

      const taxFee = parseFloat(amount) * 0.025;

      const total = parseFloat(amount) + taxFee;

      setAddedFee(taxFee.toFixed(2));

      setTotalAmount(total.toFixed(2));
    };

    calculateTotalAmount();
  }, [amount]);

  useEffect(() => {
    if (coverFees) {
      setFinalAmount(totalAmount);
      setFeeClicked("true");
    } else {
      setFinalAmount(amount);
      setFeeClicked("false");
    }
  }, [coverFees, amount, totalAmount]);

  useEffect(() => {
    const calculatedAmount = coverFees ? totalAmount : amount;
    setPaymentFinalAmount(calculatedAmount);
  }, [coverFees, totalAmount, amount]);

  useEffect(() => {
    if (isFormSubmitted) {
      // Start a timeout to re-enable the button after 15 seconds
      const timeoutId = setTimeout(() => {
        setButtonDisabled(false);
        setFormSubmitted(false);
      }, 15000);

      // Clean up the timeout on component unmount or when the form is submitted again
      return () => clearTimeout(timeoutId);
    }
  }, [isFormSubmitted]);

  const handleCoverFeesChange = (e) => {
    setCoverFees(e.target.checked);
  };

  const handleButton = (event) => {
    setDonationType(event.target.value);
    // Reset the selectedMonths to 3 when switching to monthly donation type
    if (event.target.value === MONTHLY) {
      setSelectedMonths(12);
    }
  };

  const handleMonthsButton = (months) => {
    setSelectedMonths(months);
  };

  const renderMonthsButtons = () => {
    if (donationType === MONTHLY) {
      return (
        <div className="monthsButtonContainer">
          <button
            className={
              selectedMonths === 3 ? "selectedMonthButton" : "monthButton"
            }
            onClick={() => handleMonthsButton(3)}
          >
            3 Months
          </button>
          <button
            className={
              selectedMonths === 6 ? "selectedMonthButton" : "monthButton"
            }
            onClick={() => handleMonthsButton(6)}
          >
            6 Months
          </button>
          <button
            className={
              selectedMonths === 9 ? "selectedMonthButton" : "monthButton"
            }
            onClick={() => handleMonthsButton(9)}
          >
            9 Months
          </button>
          <button
            className={
              selectedMonths === 12 ? "selectedMonthButton" : "monthButton"
            }
            onClick={() => handleMonthsButton(12)}
          >
            12 Months
          </button>
        </div>
      );
    }
    return null;
  };

  // const handleExpirationDateChange = (e) => {
  //   const input = e.target.value;
  //   setExpirationDate(input);
  //   console.log(input, " input");

  //   // Regular expression to validate the "MM/YY" format
  //   // const datePattern = /^(0[1-9]|1[0-2])\/\d{2}$/;

  //   // if (!datePattern.test(input)) {
  //   //   // setExpirationError("Please enter a valid expiration date (MM/YY)");
  //   //   setExpirationError("");
  //   // } else {
  //   //   setExpirationError("");
  //   // }
  // };

  // const paymentFinalAmount = coverFees ? totalAmount : amount;

  const handleSubmit = async (event) => {
    event.preventDefault();

    setButtonDisabled(true);
    setFormSubmitted(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error("Error creating payment method:", error);
      return;
    }

    const memoWithPrefix = "FFE: " + memo;

    const paymentData = {
      payment_method: paymentMethod.id,
      email,
      first_name: firstName,
      last_name: lastName,
      amount: Math.round(amount * 100), // Convert to cents
      months: selectedMonths,
      // message: memo,
      message: memoWithPrefix,
      Processingfeeamt: addedFee,
      payedFee: feeClicked,
      total: Math.round(paymentFinalAmount * 100),
    };

    if (donationType === MONTHLY) {
      paymentData.duration = selectedMonths;
    }

    const endpoint =
      donationType === ONE_TIME
        ? `${API_BASE_URL}/api/flowers/charge`
        : `${API_BASE_URL}/api/flowers/subscribe`;

    try {
      const response = await axios.post(endpoint, paymentData);

      if (response.data.success) {
        // alert("Payment successful!");

        navigate("/success");
      } else {
        alert("Payment failed. Please try again.");
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      alert("Payment failed. Please try again.");
    }
  };

  const optionsChrome = [
    { id: 1, text: "CREDIT CARD" },
    { id: 2, text: "GOOGLE PAY" },
    { id: 6, text: "ZELLE" },
    { id: 4, text: "DONORS FUND" },
    { id: 5, text: "OJC FUND" },
    { id: 7, text: "PLEDGER" },
    { id: 8, text: "" },
  ];
  const optionsSafari = [
    { id: 1, text: "CREDIT CARD" },
    { id: 2, text: "APPLE PAY" },
    { id: 6, text: "ZELLE" },
    { id: 4, text: "DONORS FUND" },
    { id: 5, text: "OJC FUND" },
    { id: 7, text: "PLEDGER" },
    { id: 8, text: "" },
  ];

  const options = window.chrome ? optionsChrome : optionsSafari;

  const handleItemClick = (itemId) => {
    setClickedItem(itemId);
    // if (itemId === 2 && amount !== prevAmountRef.current) {
    //   setTimeout(() => {
    //     setClickedItem(7); // ZELLE
    //     setTimeout(() => {
    //       setClickedItem(2); // GOOGLE PAY
    //     }, 1100);
    //   }, 1100);
    // }
  };

  // if (!stripe || !elements) {
  //   return <div>Loading...</div>;
  // }

  console.log(amount, " amount");
  console.log(addedFee, " addedFee");
  console.log(feeClicked, " feeClickedAmount");
  console.log(totalAmount, " totalAmount");
  console.log(paymentFinalAmount, " paymentFinalAmount");
  return (
    <form
      name="contact"
      data-netlify={true}
      onSubmit={handleSubmit}
      className="donation-form"
    >
      <input type="hidden" name="form-name" value="donations" />

      <div id="donate"></div>
      <div className="donation-info-cont">
        <h1 className="donation-info-title">Donation Info</h1>

        <div className="inputFieldDonation">
          <input
            type="number"
            min="1"
            placeholder="DONATION AMOUNT"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />

          <div className="buttonContainer">
            <button
              className={`${
                donationType === ONE_TIME ? selectedButton : ""
              } left-button`}
              style={{
                color: donationType === ONE_TIME ? "white" : "#131f67",
              }}
              onClick={handleButton}
              value={ONE_TIME}
            >
              GIVE ONCE
            </button>
            <button
              className={`${
                donationType === MONTHLY ? selectedButton : ""
              } right-button`}
              style={{
                color: donationType === MONTHLY ? "white" : "#131f67",
              }}
              onClick={handleButton}
              value={MONTHLY}
            >
              MONTHLY DONATION
            </button>
          </div>
        </div>
        {renderMonthsButtons()}

        <div className="donationcheckCont">
          <input
            className="donationcheck"
            type="checkbox"
            checked={coverFees}
            onChange={handleCoverFeesChange}
          />
          <h1>
            Please click here if you’d like to cover the
            {clickedItem === 4 ||
            clickedItem === 5 ||
            clickedItem === 6 ||
            clickedItem === 7 ? (
              <span> </span>
            ) : (
              <span> credit card </span>
            )}
            processing fees for your donation. This will change your donation
            total to ${amount === "" ? "" : totalAmount}
          </h1>
        </div>
      </div>

      <div>
        <h1 className="donation-info-title">Payment Info</h1>
      </div>

      {/* <div className="payment-options-cont">
        {options.map((option) => (
          <div
            key={option.id}
            onClick={() => handleItemClick(option.id)}
            className="payment-options"
            // className={`payment-options ${option.id === 8 ? "hide8" : ""}`}
            style={{
              display: "inline-block",
              borderRadius: "25px",
              border: clickedItem === option.id ? "none" : "2px solid #131f67",
              backgroundColor:
                clickedItem === option.id ? "#131f67" : "transparent",
              color: clickedItem === option.id ? "white" : "#131f67",
              margin: "10px",
              textAlign: "center",
              lineHeight: "40px",
              cursor: "pointer",
            }}
          >
            {option.text}
          </div>
        ))}
      </div> */}

      <div className="payment-options-cont">
        {options.map(
          (option) =>
            // Check if the text is not an empty string before rendering
            option.text !== "" && (
              <div
                key={option.id}
                onClick={() => handleItemClick(option.id)}
                className="payment-options"
                style={{
                  display: "inline-block",
                  borderRadius: "25px",
                  border:
                    clickedItem === option.id ? "none" : "2px solid #131f67",
                  backgroundColor:
                    clickedItem === option.id ? "#131f67" : "transparent",
                  color: clickedItem === option.id ? "white" : "#131f67",
                  margin: "10px",
                  textAlign: "center",
                  lineHeight: "40px",
                  cursor: "pointer",
                }}
              >
                {option.text}
              </div>
            )
        )}
      </div>

      {clickedItem === 1 ? (
        <div>
          <FormInput
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            placeholder="FIRST NAME"
          ></FormInput>

          <FormInput
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            placeholder="LAST NAME"
          ></FormInput>

          <FormInput
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="EMAIL"
          ></FormInput>

          <FormInput
            type="text"
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
            required
            placeholder="MESSAGE"
          ></FormInput>

          <div className="payment-methods">
            <CardElement />
            {/* <Elements stripe={stripePromise}>
              <PaymentRequestButton amount={paymentFinalAmount} />
            </Elements> */}
          </div>
        </div>
      ) : null}

      {clickedItem === 2 ? (
        <div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            {/* <Form /> */}
            <form className="inputForm" action="">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <FormInput
                    id={"contactName"}
                    placeholder={"FIRST NAME"}
                    type={"text"}
                    borderRight={"2px solid #131f67"}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  >
                    FIRST NAME
                  </FormInput>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <input
                    id={"contactLastname"}
                    placeholder={"LAST NAME"}
                    type={"text"}
                    className="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <FormInput
                    id={"contactPhone"}
                    placeholder={"PHONE"}
                    type={"text"}
                    borderRight={"2px solid #131f67"}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  >
                    PHONE
                  </FormInput>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <input
                    id={"contactEmail"}
                    placeholder={"EMAIL"}
                    type={"text"}
                    className="lastName"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="messageFieldForm">
                <label htmlFor="contactTextArea">MESSAGE</label>
                <textarea
                  placeholder="MESSAGE"
                  id="contactTextArea"
                  cols="1"
                  rows="2"
                  value={memo}
                  onChange={(e) => setMemo(e.target.value)}
                ></textarea>
              </div>
              {/* <button className="submitButton">SUBMIT</button> */}
            </form>
          </div>
          {/* <h1>Google Pay</h1> */}
          <Elements stripe={stripePromise}>
            <PaymentRequestForm
              amount={paymentFinalAmount}
              total={paymentFinalAmount}
              donationType={donationType}
              email={email}
              firstName={firstName}
              lastName={lastName}
              months={selectedMonths}
              message={"FFE: " + memo}
              Processingfeeamt={addedFee}
              payedFee={feeClicked}
            />
          </Elements>
        </div>
      ) : null}

      {clickedItem === 8 ? (
        <div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            {/* <Form /> */}
            <form className="inputForm" action="">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <FormInput
                    id={"contactName"}
                    placeholder={"FIRST NAME"}
                    type={"text"}
                    borderRight={"2px solid #131f67"}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  >
                    FIRST NAME
                  </FormInput>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <input
                    id={"contactLastname"}
                    placeholder={"LAST NAME"}
                    type={"text"}
                    className="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <FormInput
                    id={"contactPhone"}
                    placeholder={"PHONE"}
                    type={"text"}
                    borderRight={"2px solid #131f67"}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  >
                    PHONE
                  </FormInput>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <input
                    id={"contactEmail"}
                    placeholder={"EMAIL"}
                    type={"text"}
                    className="lastName"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="messageFieldForm">
                <label htmlFor="contactTextArea">MESSAGE</label>
                <textarea
                  placeholder="MESSAGE"
                  id="contactTextArea"
                  cols="1"
                  rows="2"
                  value={memo}
                  onChange={(e) => setMemo(e.target.value)}
                ></textarea>
              </div>
              {/* <button className="submitButton">SUBMIT</button> */}
            </form>
          </div>
          {/* <h1>Google Pay</h1> */}
          <Elements stripe={stripePromise}>
            <PaymentRequestForm
              amount={paymentFinalAmount}
              donationType={donationType}
              email={email}
              firstName={firstName}
              lastName={lastName}
              months={selectedMonths}
              message={memo}
            />
          </Elements>
        </div>
      ) : null}

      {/* {clickedItem === 3 ? (
        <div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <Form />
          </div>
           <h1>Apple Pay</h1>
          <Elements stripe={stripePromise}>
            <PaymentRequestForm amount={amount} />
          </Elements>
        </div>
      ) : null} */}

      {clickedItem === 4 ? (
        <div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <Form />
          </div>
          {/* <h1>TDF Card Number or Email</h1> */}
          <FormInput
            type="any"
            value={dfnInfo}
            onChange={(e) => setDfnInfo(e.target.value)}
            required
            placeholder="TDF Card Number or Email"
          ></FormInput>

          {/* <h1>TDF CVC or Pin</h1> */}
          <FormInput
            type="number"
            value={dfnCVCPIN}
            onChange={(e) => setDfnCVCPIN(e.target.value)}
            required
            placeholder="TDF CVC or Pin"
            className="tdfCVCTurnOffArrows"
          ></FormInput>
        </div>
      ) : null}

      {clickedItem === 5 ? (
        <div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <Form />
          </div>
          {/* <h1>OJC Card Number</h1> */}

          <InputMask
            mask="9999 9999 9999 9999"
            maskChar=" "
            // maskPlaceholder=""
            placeholder="OJC Card Number"
            required
            value={cardNumber}
            // onChange={(e) => console.log(e.target.value)}
            onChange={(e) => setCardNumber(e.target.value)}
            className="inputMask"
            style={{
              backgroundColor: "transparent",
            }}
          />

          {/* <h1>OJC Expiration Date</h1> */}

          <InputMask
            mask="99/99"
            // maskPlaceholder=""
            value={expirationDate}
            // onChange={handleExpirationDateChange}
            onChange={(e) => setExpirationDate(e.target.value)}
            required
            placeholder="OJC Expiration Date"
            className="inputMask"
            style={{
              backgroundColor: "transparent",
            }}
          />

          {/* {expirationError && <span className="error">{expirationError}</span>} */}
        </div>
      ) : null}

      {clickedItem === 6 ? (
        <div>
          <div className="zelle-cont">
            <h1>
              If you would like to send a Zelle payment to DMF, please add DMF
              as a recipient using the email address,
              <span className="email"> info@dmfcares.org</span>
              <br />
              Please make sure to indicate in the memo the purpose of your
              donation. If you would like a receipt for your donation, please
              email info@dmfcares.org with your mailing and email addresses.
            </h1>
          </div>
        </div>
      ) : null}

      {clickedItem === 7 ? (
        <div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <Form />
          </div>
          {/* <h1>PLEDGER</h1> */}
          <FormInput
            type="number"
            value={cardNumberPledger}
            onChange={(e) => setCardNumberPledger(e.target.value)}
            required
            placeholder="PLEDGER Card Number"
          ></FormInput>

          <InputMask
            mask="99/99"
            // maskPlaceholder=""
            value={expirationDatePledger}
            onChange={(e) => setExpirationDatePledger(e.target.value)}
            required
            placeholder="PLEDGER Expiration Date"
            className="inputMask"
            style={{
              backgroundColor: "transparent",
            }}
          />
        </div>
      ) : null}

      {clickedItem !== 6 && clickedItem !== 2 ? (
        <div className="donate-button-cont">
          {isButtonDisabled ? (
            <img
              src={loading}
              alt="loading"
              style={{
                width: "70px",
              }}
            />
          ) : (
            <>
              <button
                type="submit"
                className="donate-button"
                disabled={isButtonDisabled}
                style={{
                  backgroundColor: isButtonDisabled ? "#ff9d89" : "#f6785c",
                }}
              >
                DONATE
              </button>

              <img src={rightArrow} alt="right arrow" className="rightArrow" />
            </>
          )}

          <div
            style={{
              marginLeft: "10px",
              marginTop: "15px",
              color: "#131f67",
              fontSize: amount === "" ? "0.8rem" : "1.3rem",
              textAlign: amount === "" ? "center" : "left",
            }}
            className="finalAmount"
          >
            <span
              style={{
                marginRight: "2px",
              }}
            >
              $
            </span>
            <span className="finalAmount">
              {amount === "" ? "DONATION AMOUNT " : finalAmount}
            </span>
          </div>
        </div>
      ) : null}
    </form>
  );
};

export default DonationForm;
