import React, { createContext, useState } from "react";

export const ThemeContext = createContext();

const NavbarContextProvider = ({ children }) => {
  const [isNavBarOpen, setIsNavBarOpen] = useState(false);
  const [isManuallyClosed, setIsManuallyClosed] = useState(false);

  const toggleNav = () => {
    // setIsNavBarOpen((prev) => !prev);
    // if (!isNavBarOpen) {
    //   setIsManuallyClosed(false);
    // }
  };

  return (
    <ThemeContext.Provider
      value={{
        isNavBarOpen,
        toggleNav,
        setIsNavBarOpen,
        isManuallyClosed,
        setIsManuallyClosed,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default NavbarContextProvider;
