import React from "react";
import Footer from "../components/Footer";
import QuoteSection from "../components/QuoteSection";

import Contact from "../components/Contact";
import Navbar from "../components/Navbar";
import "./SuccessPage.css";
import NavbarContextProvider from "../utils/NavbarContextProvider";
import flowerBg from "../images/Flowers.png";

export default function SuccessPage() {
  return (
    <NavbarContextProvider>
      <div className="donatePage">
        {/* <Navbar /> */}

        <div>
          <img className="bg" alt="bg" src={flowerBg}></img>
        </div>

        <section className="succes-cont">
          {/* <h1>Thank you for</h1>
          <h1>bringing dignified relief</h1>
          <h1>to a local family.</h1> */}

          <h2>Your donation has been submitted.</h2>

          <h3>You will receive a confirmation email shortly. </h3>
        </section>

        <QuoteSection
          backgroundColor={"rgba(255, 157, 137, 0.45)"}
          textColor={"#131f67"}
          marginBottom={"90px"}
        >
          <p
            style={{
              marginBottom: "10px",
            }}
          >
            “My children and I have established a fund dedicated to carrying on
            Eli's legacy of Chessed. During our Shiva period, we heard numerous
            heartwarming stories of his quiet acts of kindness. The stories that
            touched us the most, were the ones in which we were told that Eli
            would bring flowers to widows, widowers, or grieving parents every
            Shabbos. It brought smiles to their faces and made them feel
            cherished.
          </p>
          <p
            style={{
              marginBottom: "10px",
            }}
          >
            Our initiative, 'Flowers From Eli,' will deliver flowers weekly to
            those who have lost a loved one, offering them comfort during such a
            difficult time.
          </p>
          <p>
            Our hope is that these recipients will sense Eli's love and care
            from above. No contribution is too small, for Eli always approached
            even the smallest acts of kindness with boundless enthusiasm. Thank
            you all for taking part in this meaningful mitzvah.”
          </p>
        </QuoteSection>

        {/* <section
          id={"contact"}
          style={{
            maxWidth: "1900px",
            margin: "0 auto",
            width: "75%",
          }}
        >
          <Contact />
        </section> */}

        <Footer />
      </div>
    </NavbarContextProvider>
  );
}
