import React from "react";

export default function FooterLinkColumns({ children, data }) {
  const listItems = data.map((item, index) => {
    if (item.type === "email") {
      return (
        <li key={index}>
          <a href={`mailto:${item.value}`}>{item.value}</a>
        </li>
      );
    } else if (item.type === "phone") {
      return (
        <li key={index}>
          <a href={`tel:${item.value}`}>{item.value}</a>
        </li>
      );
    } else {
      return <li key={index}>{item.value}</li>;
    }
  });

  return (
    <div className="footer-column">
      <h3>{children} </h3>
      <ul>{listItems}</ul>
    </div>
  );
}
