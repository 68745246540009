import React from "react";
import "./Form.css";
import FormInput from "./FormInput";

export default function Form() {
  return (
    <form name="contact" data-netlify={true} className="inputForm" action="">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "50%",
          }}
        >
          <FormInput
            id={"contactName"}
            placeholder={"FIRST NAME"}
            type={"text"}
            borderRight={"2px solid #131f67"}
          >
            FIRST NAME
          </FormInput>
        </div>

        <div
          style={{
            width: "50%",
          }}
        >
          {/* <FormInput */}
          {/* id={"contactLastname"}
            placeholder={"LAST NAME"}
            type={"text"}
          > */}
          <input
            id={"contactLastname"}
            placeholder={"LAST NAME"}
            type={"text"}
            className="lastName"
          />
          {/* </FormInput> */}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "50%",
          }}
        >
          <FormInput
            id={"contactPhone"}
            placeholder={"PHONE"}
            type={"text"}
            borderRight={"2px solid #131f67"}
          >
            PHONE
          </FormInput>
        </div>

        <div
          style={{
            width: "50%",
          }}
        >
          {/* <FormInput id={"contactEmail"} placeholder={"EMAIL"} type={"text"}> */}

          <input
            id={"contactEmail"}
            placeholder={"EMAIL"}
            type={"text"}
            className="lastName"
          />
          {/* EMAIL */}
          {/* </FormInput> */}
        </div>
      </div>

      <div className="messageFieldForm">
        <label htmlFor="contactTextArea">MESSAGE</label>
        <textarea
          placeholder="MESSAGE"
          id="contactTextArea"
          cols="1"
          rows="2"
        ></textarea>
      </div>
      {/* <button className="submitButton">SUBMIT</button> */}
    </form>
  );
}
