import React from "react";
import Footer from "../components/Footer";
import QuoteSection from "../components/QuoteSection";
import DonationForm from "../DonationForm";
import Contact from "../components/Contact";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import bg from "../images/flowers-bg.jpg";
import flowerBg from "../images/Flowers.png";

import "./DonatePage.css";
import NavbarContextProvider from "../utils/NavbarContextProvider";

export default function DonatePage() {
  return (
    <NavbarContextProvider>
      <div className="donatePage">
        {/* <Navbar /> */}

        {/* <Hero /> */}

        <div>
          <img className="bg" alt="bg" src={flowerBg}></img>
        </div>

        <section className="donationFormSection">
          <DonationForm />
        </section>

        <QuoteSection
          backgroundColor={"rgba(255, 157, 137, 0.45)"}
          textColor={"#131f67"}
          marginBottom={"90px"}
        >
          <p
            style={{
              marginBottom: "10px",
            }}
          >
            “My children and I have established a fund dedicated to carrying on
            Eli's legacy of Chessed. During our Shiva period, we heard numerous
            heartwarming stories of his quiet acts of kindness. The stories that
            touched us the most, were the ones in which we were told that Eli
            would bring flowers to widows, widowers, or grieving parents every
            Shabbos. It brought smiles to their faces and made them feel
            cherished.
          </p>
          <p
            style={{
              marginBottom: "10px",
            }}
          >
            Our initiative, 'Flowers From Eli,' will deliver flowers weekly to
            those who have lost a loved one, offering them comfort during such a
            difficult time.
          </p>
          <p>
            Our hope is that these recipients will sense Eli's love and care
            from above. No contribution is too small, for Eli always approached
            even the smallest acts of kindness with boundless enthusiasm. Thank
            you all for taking part in this meaningful mitzvah.”
          </p>
        </QuoteSection>

        {/* <section
          id="contact"
          style={{
            maxWidth: "1900px",
            margin: "0 auto",
          }}
          className="contactSection"
        >
          <Contact />
        </section> */}

        <Footer />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            flexDirection: "column",
            backgroundColor: "#131f67",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          <div className="copyrightTextDesktop">
            <p> Copyright Davis Memorial Fund. All Rights Reserved. ©2023</p>
          </div>
        </div>
      </div>
    </NavbarContextProvider>
  );
}
